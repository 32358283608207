import React, { useEffect } from "react"
import qs from "qs"
import kwesforms from "kwesforms"
import Layout from "../../../components/layout"

import Section from "../../../components/Section"
import Image from "../../../components/actions/Image"
import Seo from "../../../components/seo"
import Input from "../../../components/actions/Input"
import { useForm } from "react-hook-form"
export default function Form(props) {
  // console.log(props)
  useEffect(() => {
    kwesforms.init()
  }, [])

  const value = qs.parse(
    typeof window !== "undefined"
      ? window.location.search.substring(1)
      : `name=test`
  )
  return (
    <Layout>
      <Seo title={"Book Service " + value.name} />
      <Section>
        <Image booked name={"Service " + value.name} src={value.image} />
        <Forms value={value} />
      </Section>
    </Layout>
  )
}

const Forms = ({ value }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    // defaultValues: {
    //   start: addDays(new Date(), 14),
    //   end: addDays(new Date(), 21),
    //   adults: 4,
    //   children: 2,
    // },
  })
  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="kwes-form"
        // action="https://kwes.io/api/foreign/forms/ioOY3NcSQZV5qGeQ626w"
        // redirect="/actions/villa"
      >
        <div className="shadow-xl p-4 lg:p-8 grid grid-cols-1 lg:grid-cols-3 gap-4 xl:gap-8">
          <h4 className="col-span-1 lg:col-span-3 text-[24px] font-serif">
            Your Details
          </h4>
          <Input
            rule="required|max:255"
            label="Service Name"
            name="name"
            type="text"
            field="service-name"
            register={register}
            value={value.name}
            readOnly
          />
          <Input
            rule="required"
            label="Your Email"
            name="email"
            type="email"
            field="email"
            register={register}
          />
          <Input
            rule="required"
            label="Your Phone"
            name="phone"
            type="phone"
            field="phone"
            register={register}
            className="col-start-1 row-start-4 lg:row-start-3"
          />
          <Input
            rule="required"
            label="Your Country"
            name="country"
            field="country"
            register={register}
            type="country"
            className="row-start-5 lg:row-start-3"
          />
        </div>
        <div className="shadow-xl p-4  lg:p-8 grid grid-cols-1 lg:grid-cols-3 gap-4 xl:gap-8 mt-8">
          <h2 className="col-span-1 lg:col-span-3 text-[24px] font-serif">
            Your Requirements
          </h2>
          <Input
            rule="required"
            label="Booking Date"
            name="booking-date"
            field="booking-date"
            register={register}
            type="date"
            value={value.BookingDate}
            readOnly
          />

          <Input
            rule="required"
            label="Adults"
            name="Adults"
            field="adults"
            register={register}
            type="number"
            value={value.adults}
            readOnly
          />
          <Input
            rule="required"
            label="Childrens"
            name="Childrens"
            field="childrens"
            register={register}
            type="number"
            value={value.children}
            readOnly
          />
          <Input
            textarea
            field="message"
            register={register}
            rule="required"
            label="Let us know any further requirements"
            name="notes"
            className="col-span-1 lg:col-span-3"
          />
        </div>

        <div className="mt-8 mr-4 flex justify-end items-center">
          <button
            type="submit"
            className="px-12 py-4 outline-none focus:outline-none text-sm font-bold text-white bg-black"
          >
            Book Now
          </button>
        </div>
      </form>
    </div>
  )
}
